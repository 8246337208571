*, *::before, *::after {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

:root {
  --primary-color: #3b69d2;
  --secondary-color: #5c86e5;
  --accent-color: #799cf0;
  --background-color: #ffffff;
  --border-color: #f0f0f0;
  --text-color: #000;
  --soft-text-color: #555;
  --button-color: #000;
  --button-hover-color: #555;
  --status-up: #2fcc66;
  --status-down: #e74b3c;
}

table {
  border-collapse: collapse;
}

th, td {
  /* border-bottom: 1.5px solid var(--border-color); */
}

th {
}

tr:hover {
  /* background-color: var(--border-color); */
}

td {
}

th, td, tr {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (min-width: 1000px) {
  th, td, tr {
    padding: 10px;
  }
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

h1, h2, h3, h4 {
  padding: 20px;
  color: var(--text-color);
  font-weight: 500;
}

.link-left {
  padding-left: 0px;
}

p {
  padding: 0px;
}

.App {
  align-items: center;
  margin: 0 auto;
  color: var(--soft-text-color);
}

@media only screen and (min-width: 1000px) {
  .bordered-section {
    width: 48%;
  }
}

.goose {
  display: none;
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
  border: 1px solid var(--border-color);
  padding: 10px;
}

.scroll-block {
  display: inline-block;
  margin-right: 20px;
  padding: 15px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.container {
  display: flex;
  justify-content: left;
  align-items: left;
  font-size: 20px;
}

.wave {
  display: inline-block;
}

.globe {
  display: inline-block;
  animation: rotate-globe 1s infinite;
}

/* .headpad {
  padding: 8px;
} */

.logo {
  padding: 1px;
  height: 1em;
  transform: translateY(3px);
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: none;
  margin: 0;
}

.copy-button {
  color: var(--button-color);
  border: none;
  margin: 8px;
  padding: 4px;
  border-radius: 2px;
  width: 60px;
  text-align: center;
  background-color: transparent;
  cursor: pointer;
}

.copy-button:hover {
  color: var(--button-hover-color);
  background-color: transparent;
}

.bordered-section {
  border-top: 0px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px auto; /* Centers the content */
  /* width: 48%; */
  max-width: 1000px; /* Set a maximum width */
  border-left: none;
  border-right: none;
}

.bordered-section-content {
  margin: 20px;
}

/* @media only screen and (max-width: 600px) {
  header {
    padding-bottom: 0; 
    margin-bottom: 0;
    width: 100%;
  }

  header img {
    height: auto;
    max-height: 75px;
    margin-bottom: 0;
  }
} */

a:link, a:visited {
  color: var(--primary-color);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: var(--accent-color);
  background-color: transparent;
  text-decoration: underline;
}


@media only screen and (max-width: 600px) {
  .copy-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .copy-button {
    /* width: 10%; Ensure full width on mobile */
    /* padding: 0px 10px; */
    margin: 10px 0;
    font-size: 14px; /* Increase font size for readability */
  }
}

@media only screen and (min-width: 601px) {
  .copy-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    margin: 5px;
  }
}

code {
  background-color: var(--background-color);
  padding: 4px 8px;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  overflow-wrap: break-word;
}

.try-me-button {
  background-color: transparent;
  border-color: var(--border-color);
  font-size: 16px;
  color: var(--primary-color);
  border-radius: 5px;
  padding: 5px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  margin: 10px;
  background-color: transparent;
  border-color: var(--border-color);
  font-size: 16px;
  color: var(--primary-color);
  border-radius: 5px;
  padding: 5px;

  border: 2px solid var(--border-color);
  display: inline-block;
  cursor: pointer;
}

.custom-file-upload:hover {
  color: var(--accent-color);
  text-decoration: underline;
}

button {
  background-color: transparent;
  border: 2px solid var(--border-color);
  font-size: 16px;
  color: var(--primary-color);
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  box-shadow: none; /* Remove any browser default shadow */
  outline: none; /* Remove the outline on focus */
  appearance: none; /* Normalize the appearance */
}

button:hover {
  color: var(--accent-color);
  background-color: transparent;
}

.try-me-button:hover {
  color: var(--accent-color);
  text-decoration: underline;
}

.try-me-container {
}

.api-output {
  margin: 10px;
  background-color: transparent;
  border: 1px solid rgb(240, 240, 240); /* Ensure you're using 'border' instead of just 'border-color' */
  font-size: 16px;
  border-radius: 5px;
  padding: 10px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  /* gap: 40px; */
  /* padding: 20px 0; */
}

.bordered-section.footer-section {
  background-color: #f9f9f9;
}

.jank {
  font-size: smaller;
  margin: 15px 28%;
}

@media only screen and (max-width: 1000px) {
  .jank {
  margin: 15px 0;
  }
}

.jupyter-container {
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
  .jupyter-container {
    width: 100%;
  }
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem; /* Optional spacing */
}

.iframe-wrapper {
  flex: 1;
  max-width: 50%; /* Default for larger screens */
}

@media (max-width: 768px) {
  .iframe-wrapper {
    max-width: 100%; /* Full width on mobile devices */
  }
}

/* html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
} */

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}


footer {
  margin-top: auto;
  width: 100%;
}
